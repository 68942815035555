@import "~mixins/media-queries";

.root {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto 60px;
  box-sizing: border-box;
}

.headline {
  // HACK: We're correcting some strange styling in the Modules/Heading component.
  margin-left: -20px;
  margin-right: -20px;

  @include larger-than-laptop {
    margin-left: 0;
    margin-right: 0;
  }
}

.blocks {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.4rem;

  @include larger-than-tablet {
    grid-template-columns: 1fr 1fr;
  }
}

.block {
}
