@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.slide {
  margin-bottom: 0;
  min-height: pxToRem(336);

  @include larger-than-phone {
    min-height: pxToRem(390);
  }
}

.carousel {
  min-width: 100vw;
  width: 100vw;

  @include larger-than-tablet {
    margin-bottom: pxToRem(-20);
  }

  + :global(.rfm-marquee-container) {
    @include larger-than-tablet {
      margin-top: pxToRem(20);
    }
  }
}
