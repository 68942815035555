@import "mixins/media-queries/index";

.carouselItem {
  .cardContent {
    margin-right: 12px;
    margin-bottom: 8px;
  }

  @include larger-than-tablet {
    &:last-child {
      .cardContent {
        margin-right: 0;
      }
    }
  }
}

.designerCarousel {
  $arrow-size: 30px;

  :global {
    .-right-arrow,
    .-left-arrow {
      top: 38%;
      width: $arrow-size;
      height: $arrow-size * 2;
      background-color: var(--colors--white-background);

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .-right-arrow {
      border-bottom-left-radius: $arrow-size * 2;
      border-top-left-radius: $arrow-size * 2;
    }

    .-left-arrow {
      border-bottom-right-radius: $arrow-size * 2;
      border-top-right-radius: $arrow-size * 2;
    }
  }
}
