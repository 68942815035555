@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: pxToRem(1200) 100%;
}

.heroLoading {
  height: pxToRem(336);
  margin-bottom: pxToRem(95);

  @include larger-than-tablet {
    height: pxToRem(390);
    margin-bottom: pxToRem(111);
  }
}

.gridLoading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: pxToRem(24) 0;
  margin-bottom: pxToRem(50);

  @include larger-than-tablet {
    gap: pxToRem(60) pxToRem(40);
    max-width: pxToRem(1200);
    margin-left: auto;
    margin-right: auto;
  }
}

.moduleLoading {
  height: pxToRem(442);
  width: 100%;

  @include larger-than-tablet {
    height: pxToRem(495);
    width: pxToRem(373);
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: pxToRem(-1200) 0;
  }
  100% {
    background-position: pxToRem(1200) 0;
  }
}
