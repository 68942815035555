@import "mixins/media-queries/index";

.wrapper {
  margin: 0 auto 60px auto;
  max-width: 1200px;
  width: 100%;
}

.designerGrid {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 20px;
  padding-right: 3px; // Fixing content overflow caused by borders

  @include larger-than-tablet {
    grid-template-columns: repeat(6, 1fr);
  }

  @include larger-than-laptop {
    margin: 0 0;
  }
}
