@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.collections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: pxToRem(24) 0;
  margin-bottom: pxToRem(50);

  @include larger-than-phone {
    gap: pxToRem(50) pxToRem(40);
    max-width: pxToRem(1200);
    margin-left: auto;
    margin-right: auto;
  }
}
