@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.module {
  margin: 0 pxToRem(16) pxToRem(60);
  height: pxToRem(355);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include larger-than-tablet {
    height: pxToRem(370);
    max-width: pxToRem(1200);
    margin: 0 auto pxToRem(60);
  }

  &.blackOverlay {
    box-shadow: inset 0 pxToRem(-150) pxToRem(65) rgba(0, 0, 0, 0.55);
  }
}

.tag,
.headline,
.cta {
  margin: 0 pxToRem(16) pxToRem(16) pxToRem(16);

  @include larger-than-phone {
    margin-bottom: pxToRem(18);
  }
}

.tag,
.headline {
  color: var(--colors--white-background);
}

.tag {
  text-transform: uppercase;
}

.cta {
  height: pxToRem(60);

  @include larger-than-phone {
    width: pxToRem(359);
  }
}
