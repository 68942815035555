@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.slide {
  padding: 0 pxToRem(16);

  @include larger-than-tablet {
    padding: 0;
  }
}

.listing {
  display: flex;
  align-items: center;
  padding: pxToRem(8);
  border-radius: pxToRem(4);
  background-color: var(--colors--off-white-background);
  color: var(--colors--primary-text);
  text-decoration-line: none;

  + .listing {
    margin-top: pxToRem(8);
  }
}

.thumbnail {
  width: pxToRem(53);
  height: pxToRem(53);
  flex-shrink: 0;
  border-radius: pxToRem(4);
}

.info {
  display: inline-flex;
  flex-direction: column;
}

.thumbnail + .info {
  margin-left: pxToRem(10);
}

.nav {
  position: relative;
  display: flex;
  align-items: center;
  padding: pxToRem(16) 0;
}

.caret {
  position: unset;
  width: pxToRem(16);
  height: pxToRem(16);
  flex-shrink: 0;

  svg {
    color: var(--colors--primary-text);
    width: pxToRem(10);
    height: pxToRem(10);
  }
}

.dots {
  position: unset;

  button {
    background-color: var(--colors--primary-text);

    &[data-active="false"] {
      background-color: #d9d9d9;
      opacity: 1;
    }
  }
}
