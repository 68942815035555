@import "stylesheets/shared/z-index.scss";

.root {
  position: relative;
  z-index: $z-flash-message;
  display: block;
  padding: 15px 5%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  color: var(--colors--primary-text);

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }

  &.black * {
    color: var(--colors--white-background);
  }

  &.red:after {
    background-color: var(--colors--alert);
  }

  &.blue:after {
    background-color: var(--colors--blue-highlight);
  }

  &.yellow:after {
    background-color: var(--colors--warning);
  }

  &.green:after {
    background-color: var(--colors--success);
  }

  &.black:after {
    background-color: var(--colors--active);
    opacity: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  > p {
    margin: 0;
  }
}
