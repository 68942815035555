@import "~mixins/loading";
@import "~mixins/media-queries";

.root {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 60px;
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4% 2%;
  padding: 0 16px;

  @include larger-than-tablet {
    grid-template-columns: repeat(6, 1fr);
  }

  @include larger-than-laptop {
    padding: 0;
  }

  &.loading {
    @include loading(100%, 375px);
    height: 375px;
  }
}

.item {
}
