.container {
  position: relative;
  aspect-ratio: 1;
}

.image {
  border: solid 1px var(--colors--light-stroke);
  border-radius: 50%;
  height: auto;
}
