@import "mixins/media-queries/index";
@import "mixins/loading";
@import "mixins/type/index";

.fullWidthCTA {
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;

  &.short {
    @include loading(100%, 350px);
    height: 350px;
  }

  &.tall {
    @include loading(100%, 400px);
    height: 400px;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  text-decoration: none;

  &.left {
    align-items: flex-start;

    @include larger-than-phone {
      align-items: center;
    }
  }

  &.center {
    align-items: center;
    text-align: center;
  }

  &.right {
    align-items: flex-end;
    text-align: right;

    @include larger-than-phone {
      align-items: center;
      text-align: center;
    }
  }

  .title {
    @include type--display-2;
    color: var(--colors--white-background);
    width: 60%;

    @include larger-than-phone {
      text-align: center;
    }
  }

  .cta {
    margin: 20px 0;

    &.strong {
      border: 1px solid var(--colors--white-background);
    }
  }
}
