@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.content {
  position: relative;
  height: 100%;
  width: 100%;
}

.image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  padding: pxToRem(16);
  gap: pxToRem(4);
  border-radius: pxToRem(16);
  background-color: var(--colors--white-background);
  color: var(--colors--primary-text);
  position: absolute;
  bottom: pxToRem(16);
  margin: 0 pxToRem(16);
  width: calc(100% - pxToRem(32));

  @include larger-than(1200px) {
    width: pxToRem(358);
    bottom: pxToRem(24);
    left: pxToRem(24);
    margin: 0;
  }

  .tag {
    line-height: normal;
    margin-bottom: pxToRem(6);
  }

  .title {
    font-weight: var(--font-weight--regular);
  }
}
