@import "mixins/type/index";
@import "mixins/media-queries/index";

.heading {
  display: flex;
  justify-content: space-between;
}

.title {
  @include type--headline-2;
  display: inline-block;
  line-height: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  @include larger-than-laptop {
    margin-left: 0;
  }
}

.headingCtaLink {
  color: var(--colors--primary-text);
  text-decoration: none;
}

.cta {
  white-space: nowrap;

  @include type--super-link;
  float: right;
  line-height: 30px;
  margin-right: 20px;

  @include larger-than-laptop {
    margin-right: 0;
  }
}
