@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.header {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: pxToRem(39);
  margin: pxToRem(16);

  @include larger-than-tablet {
    margin: pxToRem(16) 0;
  }
}

.tag {
  color: var(--colors--tertiary-text);
}

.tag + .title {
  padding-top: pxToRem(2);
}

.title {
  line-height: 129%;
}

.module {
  width: 100%;

  @include larger-than-tablet {
    width: pxToRem(373);
  }
}

.doubleWide {
  height: pxToRem(496);
  margin-top: pxToRem(10);

  @include larger-than-tablet {
    margin-top: 0;
  }

  @include larger-than(1200px) {
    width: pxToRem(786);
  }
}

.link {
  text-decoration: none;
  color: var(--colors--primary-text);
}
