.ratingStars {
  display: flex;
  width: 80px;
  justify-content: space-between;

  :global(.react-tooltip-lite) {
    background: var(--colors--active);
    border-radius: 2px;
    color: var(--colors--white-background);
    font-size: 13px;
    line-height: 25px;
  }

  :global(.react-tooltip-lite-arrow) {
    border-top: 10px solid var(--colors--active);
  }
}

.star {
  width: 100%;
  height: 100%;
}

.starWrapper {
  cursor: default;
  position: relative;
  display: flex;
  margin: auto;
}
