@import "mixins/media-queries/index";

.designerCard {
  text-decoration: none;
  color: var(--colors--primary-text);
}

.contentContainer {
  border-radius: var(--shape-radius-card);
  box-sizing: border-box;
  box-shadow: var(--standard-elevation);
  border: 1px solid var(--colors--light-stroke);
  width: 156px;
  height: 212px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  @include larger-than-tablet {
    width: 190px;
    height: 250px;
  }
}

.name {
  padding: 0 8px;
  font-size: 14px;
}

.centered {
  margin: auto;
}

.photos {
  display: flex;
  overflow: hidden;

  > * {
    height: 80px;
    min-width: 0;
    object-fit: cover;
    flex: 1 1 50%;
    position: relative;

    @include larger-than-tablet {
      height: 120px;
    }
  }
}

.followButton {
  margin: 0 auto 12px;
}
