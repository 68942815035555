@import "mixins/media-queries/index";
@import "../../../app/assets/stylesheets/shared/z-index";

.carouselWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;

  &.listingPageCarousel {
    .items {
      padding-left: 0;
    }
  }
}

.leftArrow,
.rightArrow {
  display: none;

  @include larger-than-laptop {
    // NOTE(shirley): overwriting default button styles
    display: flex;
    z-index: $z-carousel-arrow;
    position: absolute;
    height: 30px;
    width: 30px;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: initial;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: none;
    }

    .arrowSvg {
      height: 15px;
      width: 15px;
      right: 0;
    }
  }
}

.rightArrow {
  right: 0;
}

.items {
  display: flex;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch; // momentum scroll for safari mobile
  padding-left: 20px;

  @include larger-than-laptop {
    padding-left: 0;
  }

  &::-webkit-scrollbar {
    display: hidden;
    height: 0;
    -webkit-appearance: none; // hide scrollbar for safari
  }
}

.item {
  flex-shrink: 0;
  transition: 0.5s transform;
}
