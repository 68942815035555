.carousel {
  position: relative;
}

.wrapper {
  overflow: hidden;
}

.container {
  display: flex;
}

.slide {
  flex: 0 0 100%;
  min-width: 0;
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.navButton {
  transition: 300ms opacity ease-in-out;
  display: none;

  &:hover {
    opacity: 0.4;
  }

  &:disabled {
    opacity: 0.2;
    &:hover {
      opacity: 0.2;
    }
  }

  @media (min-width: 771px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.next {
  position: absolute;
  top: 50%;
  right: 2.5rem;
}

.prev {
  position: absolute;
  top: 50%;
  left: 2.5rem;
}

.caret {
  color: var(--colors--white-background);
  height: 2.4rem;
  width: 2.4rem;
}

.dots {
  width: 100%;
  position: absolute;
  bottom: 1.7rem;
  display: flex;
  justify-content: center;
  gap: 0.6rem;

  & > button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background-color: var(--colors--white-background);
    opacity: 0.5;
    transition: 300ms opacity ease-in-out;

    &[data-active=true] {
      opacity: 1;
    }
  }
}
