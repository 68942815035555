@import "storefront/components/styles/pxToRem.module.scss";

.topDesigners {
  display: block;
  color: var(--colors--tertiary-text);
  font-family: var(--video-font);
  font-size: pxToRem(11);
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
