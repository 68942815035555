@import "~mixins/media-queries";
@import "~mixins/type";

.root {
  color: var(--colors--primary-text);
  text-decoration: none;
  aspect-ratio: 1;
  position: relative;
}

.image + .title {
  margin-top: 5px;
}

.title {
  @include type--subtitle-2;
  margin: 0;
  text-align: center;

  @include larger-than-tablet {
    @include type--subtitle;
    text-align: center;
  }
}
