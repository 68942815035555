@import "mixins/media-queries/index";
@import "mixins/loading";

.module {
  width: 100%;
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 60px;

  :global {
    .-right-arrow,
    .-left-arrow {
      top: 38%;
      background-color: transparent;
    }
  }
}

.carouselItem {
  width: 32.5%;
  min-width: 240px;
  margin-right: 15px;

  @include larger-than-phone {
    min-width: 270px;
  }

  @include larger-than-laptop {
    margin-right: 1.25%;
  }
}

.feedItem {
  &:global(-Image) {
    @include loading(100%, 150px);
    background-size: cover;
    background-position: center;

    @include larger-than-laptop {
      @include loading(100%, 245px);
    }
  }

  &:global(-Title) {
    text-align: left;

    @include larger-than-laptop {
      text-align: center;
    }
  }
}
