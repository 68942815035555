@import "mixins/media-queries/index";

.item {
  height: auto;
  min-width: 100px;
  margin: 0;
  margin-bottom: 10px;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    box-shadow: none;
  }

  @include larger-than-tablet {
    width: auto;
  }
}

.image {
  width: 100%;
  height: auto;
}
