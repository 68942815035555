@import "mixins/loading";
@import "mixins/media-queries/index";

.capsulesCarousel {
  $arrow-size: 30px;

  :global {
    .-right-arrow,
    .-left-arrow {
      top: 28%;
      width: $arrow-size;
      height: $arrow-size * 2;
      background-color: var(--colors--white-background);

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .-right-arrow {
      border-bottom-left-radius: $arrow-size * 2;
      border-top-left-radius: $arrow-size * 2;
    }

    .-left-arrow {
      border-bottom-right-radius: $arrow-size * 2;
      border-top-right-radius: $arrow-size * 2;
    }
  }

  .carouselItem {
    width: 32.3%;
    min-width: 240px;
    margin-right: 15px;

    @include larger-than-phone {
      min-width: 270px;
    }

    @include larger-than-laptop {
      margin-right: 1.25%;
    }

    .carouselCard {
      height: 99%;

      &:global(-Image) {
        max-height: 170px;
        @include loading(100%, 150px);

        @include larger-than-laptop {
          @include loading(100%, 245px);
        }
      }

      &:global(-Title) {
        margin-bottom: 20px;
      }

      &:global(-Description) {
        color: var(--colors--primary-text);
        display: none;

        @include larger-than-tablet {
          display: block;
        }
      }
    }
  }
}
