@import "mixins/media-queries/index";
@import "mixins/loading";

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
  position: relative;
  $arrow-size: 30px;

  @include larger-than-phone {
    margin-bottom: 56px;
  }

  :global {
    .-loading {
      @include loading(100%, 375px);
      height: 375px;
      width: 100%;
    }

    .-right-arrow,
    .-left-arrow {
      top: 26%;
      width: $arrow-size;
      height: $arrow-size * 2;
      background-color: var(--colors--white-background);

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .-right-arrow {
      border-bottom-left-radius: $arrow-size * 2;
      border-top-left-radius: $arrow-size * 2;
    }

    .-left-arrow {
      border-bottom-right-radius: $arrow-size * 2;
      border-top-right-radius: $arrow-size * 2;
    }
  }
}
