@import "mixins/media-queries/index";

.visualFilters {
  margin-bottom: 60px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  padding: 0 20px;
  box-sizing: border-box;

  &.four {
    .item {
      width: 48%;
    }
  }

  &.six {
    .item {
      width: 30%;
    }
  }

  @include larger-than-tablet {
    padding: 0;
    flex-wrap: nowrap;

    .item + .item {
      margin-left: 10px;
    }
  }
}
