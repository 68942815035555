@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.capsuleCard {
  border-radius: 2px;
  border: 1px solid var(--colors--light-stroke);
  box-shadow: 2px 2px 0px var(--colors--light-stroke);
  box-sizing: border-box;
  color: var(--colors--primary-text);
  display: block;
  width: 100%;
  max-width: 380px;
  text-decoration: none;

  .image {
    margin-bottom: 10px;
    @include larger-than-tablet {
      height: 280px;
    }
    max-height: 230px;
    min-height: 200px;
  }

  .info {
    margin: 0 20px 20px 20px;

    .title {
      margin-bottom: 10px;

      &::after {
        content: " →";
      }

      @include larger-than-phone {
        font-size: pxToRem(16);
      }
    }

    .description {
      color: var(--colors--secondary-text);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include larger-than-phone {
        font-size: pxToRem(14);
      }
    }
  }
}
