@import "~mixins/media-queries";

.root {
  display: block;
  border: 1px solid var(--colors--light-stroke);
  color: unset;
  text-decoration: none;

  &:hover {
    color: unset;
    opacity: 1;

    .button {
      color: var(--colors--white-background);
      border-color: var(--colors--blue-highlight);
      background-color: var(--colors--blue-highlight);
    }
  }
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: clamp(21rem, 13.1306rem + 19.0083vw, 34.8rem);
  padding: 0 5%;
  margin: 0;
  font-size: clamp(2.4rem, 1.7157rem + 1.6529vw, 3.6rem);
  line-height: 1.2em;
  font-weight: 700;
  text-align: center;
  color: var(--colors--white-background);
  text-shadow: 0px 0px 0.27664417em rgba(255, 255, 255, 0.75);
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: darken;
}

.title + .details {
  border-top: 1px solid var(--colors--light-stroke);
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5% 2.5% 2.5% 4%;
}

.subtitle {
  flex: 0 1 auto;
  margin: 1px 0 0;
  padding: 0.65em 1em 0.65em 0;
  font-size: clamp(1.2rem, 1.086rem + 0.2755vw, 1.4rem);
  text-transform: uppercase;

  @include larger-than-tablet {
    font-weight: 400;
  }
}

.button {
  flex: 0 0 auto;
  display: block;
  min-width: 0;
  padding: 0.5em 1em;
  font-size: clamp(1.2rem, 1.086rem + 0.2755vw, 1.4rem);
  line-height: 1.3em;
  letter-spacing: 0.02em;
  color: var(--colors--blue-highlight);
  border-color: var(--colors--blue-highlight);
  background-color: transparent;

  &:hover {
    color: var(--colors--white-background);
    border-color: var(--colors--blue-highlight);
    background-color: var(--colors--blue-highlight);
  }
}
