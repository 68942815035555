@import "mixins/media-queries/index";

.feedItem {
  height: auto;
  display: block;
  min-width: 100px;
  margin-bottom: 10px;
  text-decoration: none;
  color: var(--colors--primary-text);

  @include larger-than-tablet {
    width: auto;
  }
}

.image {
  width: 100%;
  height: auto;
}

.title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}
