@import "mixins/media-queries/index";
@import "mixins/type/index";

.homeGuideScreen {
  margin: 50px 0px;

  .title,
  .description {
    padding: 0px 15px;
  }

  .title,
  :global(.-title) {
    @include type--headline-1;

    @include larger-than-tablet {
      text-align: center;
    }
  }

  .description,
  :global(.-description) {
    @include type--body;

    @include larger-than-tablet {
      text-align: center;
    }
  }
}

.heroImage {
  height: 250px;

  @include larger-than-tablet {
    height: 430px;
  }
}

.image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
}

.routerWrapper {
  display: block;
  max-width: 1140px;

  @include larger-than-tablet {
    justify-content: center;
    display: flex;
    padding: 30px;
    margin: 0px auto;
  }
}

.backButton {
  padding: 15px;
}

.sidebar {
  margin-left: 15px;

  @include larger-than-tablet {
    width: 30%;
    margin-left: 0px;
  }
}

.guideCardModuleList {
  list-style: none;
}

.guideCardModuleListItem {
  :global {
    .Module--GuideCard {
      .-title {
        @include type--title;
        text-align: left;
      }

      .-description {
        display: none;
      }

      .-launch-item-list {
        list-style: none;

        .-launch-item-list-item {
          .launch-item {
            a {
              @include type--body;
              text-align: left;
              font-weight: bold;
              color: var(--colors--secondary-text);
            }
          }
        }

        .-launch-item-list-item + .-launch-item-list-item {
          margin-top: 20px;
        }
      }
    }
  }
}

.routeWrapper {
  padding: 15px;

  @include larger-than-tablet {
    width: 70%;
    padding: 0px;
  }

  :global {
    .-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .Module--GuideScreen {
      margin: 0;

      .-title {
        text-align: left;
        padding-left: 0px;
      }

      .-description {
        padding: 0px;
        text-align: left;
      }

      .GuideScreen--Modules {
        .-title {
          @include type--headline-2;
        }

        .-description {
          padding-left: 0px;
          margin-top: 1rem;
          margin-bottom: 3rem;
        }

        .Module--GuideCardText {
          margin-top: 4rem;

          .-description {
            color: var(--colors--secondary-text);
            white-space: pre-line;

            .-link {
              color: var(--colors--blue-highlight);
            }
          }

          ol,
          ul {
            list-style-position: outside;
            margin-left: 18px;
          }
        }

        .Module--GuideText {
          margin-top: 2rem;
          border-top: 1px solid var(--colors--light-stroke);

          ol,
          ul {
            list-style-position: outside;
            margin-left: 18px;
          }

          li {
            margin-bottom: 0;
            white-space: pre-line;
          }

          a {
            color: var(--colors--info);
          }
        }

        .Module--GuideImage {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-auto-rows: 150px;
          grid-gap: 4px;

          @include larger-than-phone {
            grid-auto-rows: 200px;
          }

          @include larger-than-tablet {
            grid-auto-rows: 250px;
          }

          .image-item {
            .-image {
              height: 150px;

              @include larger-than-phone {
                height: 200px;
              }

              @include larger-than-tablet {
                height: 250px;
              }
            }
          }
        }
      }
    }
  }
}

.description + .heroImage,
.heroImage + .routerWrapper {
  margin-top: 50px;
}

.backButton + .routeWrapper {
  margin-top: 20px;

  @include larger-than-tablet {
    padding: 0px;
  }
}

.sidebar + .routeWrapper {
  @include larger-than-tablet {
    margin-left: 100px;
  }
}

.guideCardModuleListItem + .guideCardModuleListItem {
  margin-top: 35px;
}
