@import "~mixins/media-queries";

.root {
  color: var(--colors--white-background);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 60vh;
  padding: 0 5% 15% 5%;
  text-align: left;

  @include larger-than-phone {
    align-items: center;
    overflow: hidden;
    justify-content: center;
    padding: 0 5% 0 5%;
    text-align: center;
    min-height: 70vh;
  }

  &.gradientOverlay:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 29.17%,
      rgba(0, 0, 0, 0.7) 100%
    );

    @include larger-than-phone {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

.tag {
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
}

.tag + .title {
  margin-top: 1.4rem;

  @include larger-than-phone {
    margin-top: 1.2rem;
  }
}

.title {
  color: var(--colors--white-background);
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -0.01052632em;
  max-width: 912px;

  @include larger-than-phone {
    font-size: clamp(2.4rem, 1.6017rem + 1.9284vw, 3.8rem);
  }
}

.title + .action {
  margin-top: 2.4rem;

  @include larger-than-phone {
    margin-top: 3.2rem;
  }
}

.action {
  display: none;

  @include larger-than-phone {
    display: block;
  }

  min-width: 0;
  padding: 0.92307692em 1.23076923em;
  background: transparent;
  border-color: var(--colors--white-background);
  font-size: clamp(1.3rem, 0.9008rem + 0.9642vw, 2rem);
  line-height: 1.3em;
  letter-spacing: 0.02em;

  &:hover {
    background: var(--colors--blue-highlight);
    color: var(--colors--white-background);
    border-color: var(--colors--blue-highlight);
  }

  &:active {
    top: 2px;
    position: relative;
  }
}

.videoOrImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  text-decoration: none;
}
