.module {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 60px auto;
  position: relative;
}

.articles {
  display: flex;
  justify-content: space-between;
  width: 100%;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch; // momentum scroll for safari mobile

  &::-webkit-scrollbar {
    display: hidden;
    -webkit-appearance: none; // hide scrollbar for safari
    height: 0;
  }
}
