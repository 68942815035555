@import "mixins/media-queries/index";
@import "mixins/type/index";
@import "mixins/loading";

.article {
  min-width: 240px;
  width: 33%;

  @include larger-than-phone {
    min-width: 270px;
  }

  &:first-child {
    margin-left: 20px;

    @include larger-than-tablet {
      margin-left: 0;
    }
  }
}

.article + .article {
  margin-left: 15px;
}

.image {
  @include loading(100%, 150px);
  background-position: center;
  background-size: cover;
  box-sizing: border-box;

  @include larger-than-tablet {
    @include loading(100%, 245px);
  }
}

.content {
  width: 100%;
  box-sizing: border-box;
}

.franchise {
  @include type--primary-link;
  color: var(--colors--secondary-text);
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0;
}

.title {
  @include type--body;
  font-weight: $type--weights--medium;
}
