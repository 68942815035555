@import "~mixins/media-queries";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  padding: 0 5%;
  margin-bottom: 3rem;
  overflow: hidden;
  text-align: left;
  padding: 0 5% 15% 5%;
  justify-content: flex-end;

  &.legacyStyle {
    justify-content: center;
    padding: 0 5%;
  }

  @include larger-than-phone {
    min-height: 70vh;
    margin-bottom: 6rem;
    padding: 0 5%;
    text-align: center;
    justify-content: center;
  }

  &.gradientOverlay:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 29.17%,
      rgba(0, 0, 0, 0.7) 100%
    );

    @include larger-than-phone {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

.headline {
  color: var(--colors--white-background);
  font-weight: 700;
  font-size: 34px;
  letter-spacing: -0.01052632em;
  width: 100%;

  &.legacyStyle {
    text-transform: uppercase;
    font-size: clamp(2.4rem, 1.6017rem + 1.9284vw, 3.8rem);
    text-align: center;
  }

  @include larger-than-phone {
    text-transform: uppercase;
    font-size: clamp(2.4rem, 1.6017rem + 1.9284vw, 3.8rem);
    width: auto;
  }
}

.headline + .subheadline {
  margin-top: 0.8rem;

  @include larger-than-phone {
    margin-top: 3.2rem;
  }

  &.legacyStyle {
    margin-top: 2.4rem;

    @include larger-than-phone {
      margin-top: 3.2rem;
    }
  }
}

.subheadline {
  color: var(--colors--white-background);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3em;
  padding-right: 50px;
  width: 100%;

  &.legacyStyle {
    font-size: clamp(1.8rem, 1.1446rem + 2.0661vw, 3rem);
    text-align: center;
    padding-right: 0;
  }

  @include larger-than-phone {
    font-size: clamp(1.8rem, 1.1446rem + 2.0661vw, 3rem);
    max-width: 575px;
    padding-right: 0;
  }
}

.subheadline + .actions {
  margin-top: var(--spacing-32);

  @include larger-than-phone {
    margin-top: var(--layout-40);
  }
}

.actions {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  max-width: 550px;

  @media (max-width: 375px) {
    max-width: 200px;
    flex-direction: column;
    align-items: stretch;
  }

  .action {
    min-width: 0;
    padding: 0.92307692em 1.23076923em;
    background: transparent;
    border-color: var(--colors--white-background);
    font-size: clamp(1.3rem, 0.9008rem + 0.9642vw, 2rem);
    line-height: 1.3em;
    letter-spacing: 0.02em;

    &:hover {
      background: var(--colors--blue-highlight);
      color: var(--colors--white-background);
      border-color: var(--colors--blue-highlight);
    }

    &:active {
      top: 2px;
      position: relative;
    }
  }

  .action + .action {
    @media (max-width: 375px) {
      margin-top: 1.25em;
    }
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  text-decoration: none;
}
